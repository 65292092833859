﻿
@media print {
    body {
        background-color: transparent !important;
    }
    a[href]:after {
        content: none !important;
    }

    /*Remove paddings*/
    .text-section,
    .text-section--boxed,
    .image-and-text__content,
    .grid-section__boxed-content {
        padding: 0 !important;
    }

    /*Parent elements must NOT float for "page-break" to work*/
    div[class^='col-'] {
        float: none !important;
    }

    /*Avoid breaking in the middle of a section or module*/
    /*Add more places that you don't want to break inside.*/
    .block {
        page-break-inside: avoid;
    }

    h1, h2, h3 {
        &:before, &:after {
            display: none !important;
        }
    }

    .accordion__content {
        border: 0px !important; 
    }

    .calltoactiontext{
        display: none !important;
    }

    .accordion__content, .init, .collapsible-content, .collapsible-content-collapsed {
        display: block !important;
    }

    .hide-print {
        display: none !important;
    }


    .header, footer, .contact-button, .anchor-menu-wrapper, .social-media, .js-overflow__trigger, .hero-fixed-image, .hero__highlighted-links, .breadcrumbs, .grid-video, .search, .header__menu, .umbraco-forms-form {
        display: none !important;
    }

    .hero-no-img {
        margin-top: 0 !important;
    }

    .hero-inner {
        position: static !important;
    }
    .hero__text {
        color: black !important;
        margin: 0 !important;
    }
    .hero--education {
        min-height: 0 !important;
        text-align: left !important;
    }
    .hero {
        background-color: transparent !important;
        display: block !important;

        .overlay {
            display: none !important;
        }
    }

    .hero__signup {
        position: static !important;
        display: block !important;

        * {
            font-size: 14px !important;
            line-height: 14px !important;
            background-color: transparent !important;
            display: inline !important;
        }

        .description {
            margin: 0 !important;
        }
        .signup-box {
            padding: 20px !important;
            border: 1px solid black !important;

            &__text, &__btn a {
                color: black !important;
                padding: 0 !important;
            }
        }

        a {
            margin-left: 10px !important;
        }
    }

    .js-overflow {
        max-height: none !important;
    }

    .textAndImage__wrapper {
        margin-bottom: 20px !important;
    }
    .textAndImage__box {
        margin: 0 !important;
        background-color: transparent !important;
        border: 1px solid black !important;

        * {
            color: black !important;
            background-color: transparent !important;
        }

        &--image {
            display: none !important;
        }

        &--text {
            width: 100% !important;
        }

        &--link {
            //position: static !important;
            transform: translateX(0) !important;
            top: auto !important;
            bottom: 0 !important;
            right: 0 !important;
            text-decoration: none !important;

            &--as-icon {
                bottom: 50% !important;
                transform: translateY(50%) rotate(45deg) !important;
                right: 20px !important;
            }
        }
    }



    .accordion {
        display: none !important;

        &__head {
            padding-bottom: 0 !important;

            .textAndImage__box--text {
                padding-top: 20px !important;
                background-color: #eaeaea !important;
            }
        }

        &__count {
            display: none !important;
        }

        &--active {
            display: block !important;
            //border: 1px solid black !important;

            * {
                color: black !important;
                background-color: transparent !important;
            }

            .accordion__body {
                max-height: none !important;
                border-top: 0 !important;
            }
        }
    }

    .module-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        border: 1px solid black !important;

        &::before {
            display: none !important;
        }

        * {
            color: black !important;
            background-color: transparent !important;
        }

        .module-overlay {
            position: static !important;

            &__inner {
                padding-bottom: 0 !important;
            }
        }

        .module {
            //padding-left: 0 !important;
            //padding-right: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .cta-box {
        &--link {
            text-decoration: none !important;

            h4 {
                text-decoration: underline;
                text-decoration-color: black;
            }
        }
        &__inner {
            border: 1px solid black !important;

            * {
                color: black !important;
                background-color: transparent !important;
            }
        }

        .cta-arrow {
            display: none !important;
        }
    }
}
