﻿@import "_custom-variables.scss";
@import "3rd-party/libraries/bootstrap/_mixins.scss";



@mixin custom-font() {
    font-family: $font-family-sans-serif;
}

@mixin title() {
    font-size: $font-size-h1;
    font-weight: bold;
}

@mixin equal-height-row() {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:before {
        content: none !important;
    }
}

@mixin align-vertically() {
    display: flex;
    align-items: center;
}

@mixin screen-reader-text() {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}



/*Icons*/
/*@mixin icon-circle-plus() {
    font-family: $icons-font-family;
    content: "\e904";
}

@mixin icon-arrow-up() {
    font-family: $icons-font-family;
    content: "\e903";
}

@mixin icon-arrow-down() {
    font-family: $icons-font-family;
    content: "\e900";
}

@mixin icon-arrow-left() {
    font-family: $icons-font-family;
    content: "\e901";
}

@mixin icon-arrow-right() {
    font-family: $icons-font-family;
    content: "\e902";
}*/

@mixin transition-std($target: all, $ease: ease-in-out, $duration: $animation-duration-std, $delay: 0ms) {
    transition: $target $ease $duration $delay;
}


@mixin element-theme($full-bg, $light-bg, $text) {
    color: $text;
    background: $full-bg;

    .bg-light {
        //color: $black;
        background: $light-bg;
    }
}



/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    background: $color-stops;
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}


// (min) Breakpoints
@mixin breakpoint($name) {
    $lg: "(min-width: " + $breakpoint-lg + ")";
    $ipad-landscape: "(min-width: " + $breakpoint-ipad-landscape + ")";
    $md: "(min-width: " + $breakpoint-md + ")";
    $sm: "(min-width: " + $breakpoint-sm + ")";
    $xs: "(min-width: " + $breakpoint-xs + ")";
    $xxs: "(min-width: " + $breakpoint-xxs + ")";

    @if $name == lg {
        @media #{$lg} {
            @content;
        }
    }
    @else if $name == ipad-landscape {
        @media #{$ipad-landscape} {
            @content;
        }
    }
    @else if $name == md {
        @media #{$md} {
            @content;
        }
    }
    @else if $name == sm {
        @media #{$sm} {
            @content;
        }
    }
    @else if $name == xs {
        @media #{$xs} {
            @content;
        }
    }
    @else if $name == xxs {
        @media #{$xxs} {
            @content;
        }
    }
}

// (max) Breakpoints
@mixin breakpoint-max($name) {
    $lg: "(max-width: " + ($breakpoint-lg - 1px) + ")";
    $ipad-landscape: "(max-width: " + ($breakpoint-ipad-landscape - 1px) + ")";
    $md: "(max-width: " + ($breakpoint-md - 1px) + ")";
    $sm: "(max-width: " + ($breakpoint-sm - 1px) + ")";
    $xs: "(max-width: " + ($breakpoint-xs - 1px) + ")";
    $xxs: "(max-width: " + ($breakpoint-xxs - 1px) + ")";

    @if $name == lg {
        @media #{$lg} {
            @content;
        }
    }
    @else if $name == ipad-landscape {
        @media #{$ipad-landscape} {
            @content;
        }
    }
    @else if $name == md {
        @media #{$md} {
            @content;
        }
    }
    @else if $name == sm {
        @media #{$sm} {
            @content;
        }
    }
    @else if $name == xs {
        @media #{$xs} {
            @content;
        }
    }
    @else if $name == xxs {
        @media #{$xxs} {
            @content;
        }
    }
}

/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */

@function between($to, $from, $toWidth, $fromWidth) {
    $slope: ($to - $from) / ($toWidth - $fromWidth);
    $base: $from - $slope * $fromWidth;

    @return calc(#{$base} + #{100vw * $slope});
}

/*@mixin drop-shadow() {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
}*/

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin arrow-right() {
    font-family: $icons-font-family;
    content: $kicon-arrow-right;
}

@mixin arrow-down() {
    font-family: $icons-font-family;
    content: $kicon-arrow-down;
}


/*Theme Mixin - For use in Umbraco Backend Theme Picker*/
@mixin theme-classes($theme-name) {
    .theme__#{$theme-name} {
        .primary {
            color: $brand-primary-bg__text-color;
            background: $theme-primary;
        }

        .secondary {
            display: none;
            color: $brand-secondary-bg__text-color;
            background: $brand-secondary;
        }
    }
}