﻿@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

.breadcrumbs {
    margin: 0;
    padding: $default-spacing/4 0;
    margin-top: $default-spacing;
    list-style: none;

    &__current {
    }

    li {
        display: inline-block;
        font-size: $font-size-small;
        //color: rgba($white,0.7);
        color: $text-color;

        &:before {
            content: " / ";
            //font-weight: bold;
            opacity: 0.4;
        }

        &:first-child {
            &:before {
                content: none;
            }
        }

        a {
            color: $text-color;
            text-decoration: underline;
            display: inline-block;

            &:hover {
                text-decoration: none;
                color: $text-color;
            }
        }
    }
}

.hero {
    .breadcrumbs {
        position: absolute;
        left: $default-spacing*2;
        top: $header-height + $default-spacing;
        margin: 0;
        z-index: 1;

        li {
            color: $white;
            //font-size: inherit;

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .breadcrumbs__current {
            &:before {
                content: ' / ';
            }
        }

        @include breakpoint-max(xs) {
            left: $default-spacing;
            top: $header-height-responsive + $default-spacing;

            /*li {
                font-size: $font-size-small;
            }*/
        }
    }
}

.hero--education {
    .breadcrumbs {
        li {
            &:before {
                font-family: $icons-font-family;
                content: $kicon-arrow-left;
                margin-right: $default-spacing/4;
                transform: translateY(1px);
                display: inline-block;
            }
        }

        &__current {
            display: none;
        }
    }
}

.hero-no-img {
    .breadcrumbs {
        padding-top: $default-spacing*1.5;
    }
}