﻿@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

.header {
    width: 100%;
    height: $header-height;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba($black,.5);

    .header__color-box {
        max-width: $logobox-width;
        min-width: $logobox-width - 60px;
        height: 100%;
        width: 100%;
        background-color: $brand-primary-logo;
        color: $white;
        padding: $default-spacing * 2;
        text-transform: uppercase;
        display: block;

        @include breakpoint-max(xs) {
            max-width: 100%;
            min-width: 50%;
        }

        h1, h2 {
            font-weight: 300;
            font-size: 1.5rem;
            letter-spacing: 1.6px;

            span {
                font-weight: 700;
            }
        }
    }

    .header__navigation-box {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 $default-spacing*2;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                text-transform: uppercase;

                a {
                    color: $white;
                    margin-left: $default-spacing;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .siblingLinks {
            max-width: 65%;

            @include breakpoint-max(xs) {
                display: none;
            }
        }
    }

    @include breakpoint-max(xs) {
        height: auto;
        min-height: $header-height-responsive;

        .header__color-box {
            padding: $default-spacing/2 $default-spacing;
            display: flex;
            align-items: center;
        }

        .header__navigation-box {
            padding: 0 $default-spacing;
            width: 50%;
            align-self: center;
        }
    }

    &--fake {
        background-color: transparent;
        position: static;

        .header__color-box {
            background-color: transparent;
            color: #333F48;
        }

        .menu__icons button {
            color: $black;
        }
    }
}

.header__menu {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: $white;
    transform: translateX(0);
    transition: ease-in-out .3s;
    overflow: auto;

    .specialLinks {
        list-style-type: none;
        padding: 0 $default-spacing * 2;
        margin-bottom: $default-spacing;

        @include breakpoint-max(xs) {
            margin-top: $default-spacing;
            padding: 0 $default-spacing;
        }

        li {
            display: block;
            margin-bottom: $default-spacing*0.25;

            a {
                color: $black;
                font-size: 2rem;

                @include breakpoint-max(xs) {
                    font-size: 1.4rem;
                }

                &:hover {
                    color: $brand-primary;

                    .icon {
                        transform: translateX($default-spacing / 2);
                    }
                }
            }

            span:not(.icon) {
                border-bottom: 1px solid $brand-primary;
            }

            .icon {
                color: $brand-primary;
                transition: transform .3s;
                font-size: 1.5rem;
                display: inline-block;
                margin-left: $default-spacing*0.25;

                @include breakpoint-max(xs) {
                    transform: translateY(2px);
                }
            }
        }
    }

    .basicLinks {
        list-style-type: none;
        padding: 0 $default-spacing * 2;
        margin-bottom: $default-spacing;

        @include breakpoint-max(xs) {
            padding: 0 $default-spacing;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-flow: row wrap;

            a {
                color: $black;
                padding: $default-spacing*0.25 0;
                display: inline-block;
                flex: 1;

                &:hover {
                    color: $brand-primary;
                }
            }

            ul {
                display: none;
                width: 100%;
                margin-bottom: $default-spacing;
                padding-left: $default-spacing*0.6;
                list-style-type: none;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s ease-out;

                .navigation-toggle {
                    font-size: 1em;
                    line-height: 30px;
                    height: 30px;
                }
            }

            &.active {
                > .navigation-toggle {

                    .icon {
                        &::Before {
                            content: $kicon-minus;
                        }
                    }
                }

                > ul {
                    display: block;
                }
            }
        }

        > li {
            &:not(:last-child) {
                @include breakpoint(sm) {
                    border-bottom: 1px solid $gray;
                }
            }

            > a {
                padding: $default-spacing*0.75 0;
            }

            > ul {
                font-size: .9em;
            }
        }

        .navigation-toggle {
            border: 0;
            background-color: transparent;
            display: inline-block;
            font-size: 1.2em;
            width: 40px;
            line-height: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
            outline: 0;
            padding: 0;

            @include breakpoint(sm) {
                &:hover, &:focus {
                    background-color: $gray-light;
                }
            }
        }
    }

    .siblingLinks {
        list-style-type: none;
        padding: 0;
        padding-top: $default-spacing;
        margin: $default-spacing*2 $default-spacing;
        margin-bottom: 0;
        border-top: 1px solid $gray;
        padding-bottom: $default-spacing;

        a {
            color: $black;
            padding: $default-spacing*0.25 0;
            display: inline-block;

            &:hover {
                color: $brand-primary;
            }
        }

        @include breakpoint(xs) {
            display: none;
        }
    }
}

.header__menu--hidden {
    //right: -100%;
    transform: translateX(100%);
    user-select: none;
    pointer-events: none;
    transition: ease-in-out .3s;
}

.menu__icons {
    ul {
        display: flex;
    }
    button {
        width: 40px;
        height: 40px;
        font-size: $font-size-icon + 4px;
        color: $white;
        background-color: transparent;
        border: 0;
        margin-left: $default-spacing;
        cursor: pointer;
    }
}