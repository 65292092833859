﻿/* Unlike _base.scss, this is specific utilities/helpers you have to add to your elements
 * Used for
 * * Extending on existing content
 * References:
 *    Resources\3rd-party\libraries\bootstrap\_utility.scss */
@import "../_custom-variables.scss";
@import "../_custom-mixins.scss";


.equal-height {
    @include equal-height-row;
}

.align-vertically {
    @include align-vertically;
}

.canvas-keep-aspect {
    float: left;
    width: 100%;
}



.boxed {
    @include clearfix;
    @include transition-std();
    padding: $default-spacing $default-spacing 0;
    border: 1px solid $gray-light;

    &.has-link {

        &:hover, &:focus {
       
        }
    }
    &--primary {
        color: $white;
        background: $brand-primary;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.bg {
    &-primary {
        @include element-theme($brand-primary, $brand-primary-light, $brand-primary-bg__text-color);
    }

    &-secondary {
        @include element-theme($brand-secondary, $brand-secondary-light, $brand-secondary-bg__text-color);
    }
}


//Display (block, flex, etc.)
.d {
    &-block {
        display: block;
    }

    &-inline-block {
        display: inline-block;
    }

    &-flex {
        display: flex;
    }

    &-inline {
        display: inline;
    }
}

.text {
    &--primary {
        color: $brand-primary;
    }
}

//no margin
.m-0,
.no-margin {
    margin: 0 !important;
}

//margin-top
.mt {
    margin-top: $default-spacing/2;

    &-1 {
        margin-top: $default-spacing;
    }

    &-2 {
        margin-top: $default-spacing*2 !important;
    }

    &-3 {
        margin-top: $default-spacing*2 !important;
    }

    &-4 {
        margin-top: $default-spacing*4 !important;
    }
}

//margin-bottom
.mb {
    margin-bottom: $default-spacing / 2;

    &-1 {
        margin-bottom: $default-spacing;
    }

    &-2 {
        margin-bottom: $default-spacing*2;
    }

    &-4 {
        margin-bottom: $default-spacing*4;
    }
}


//padding-top
.pt {
    padding-top: $default-spacing / 2;

    &-1 {
        padding-top: $default-spacing;
    }

    &-2 {
        padding-top: $default-spacing*2;
    }
}
//padding-bottom
.pb {
    padding-bottom: $default-spacing / 2;

    &-1 {
        padding-bottom: $default-spacing;
    }

    &-2 {
        padding-bottom: $default-spacing*2;
    }
}

@include breakpoint-max(sm) {
    //margin-top
    .mt {
        margin-top: $default-spacing/4;

        &-1 {
            margin-top: $default-spacing/2;
        }

        &-2 {
            margin-top: $default-spacing;
        }
    }
    //margin-bottom
    .mb {
        margin-bottom: $default-spacing / 4;

        &-1 {
            margin-bottom: $default-spacing/2;
        }

        &-2 {
            margin-bottom: $default-spacing*1;
        }

        &-4 {
            margin-bottom: $default-spacing*2;
        }
    }
    //padding-top
    .pt {
        padding-top: $default-spacing / 4;

        &-1 {
            padding-top: $default-spacing/2;
        }

        &-2 {
            padding-top: $default-spacing;
        }
    }
    //padding-bottom
    .pb {
        padding-bottom: $default-spacing / 4;

        &-1 {
            padding-bottom: $default-spacing/2;
        }

        &-2 {
            padding-bottom: $default-spacing;
        }
    }
}

.btn {
    @include transition-std();
    border: none;
    padding: 7px $default-spacing/1.25;
    font-family: $font-family-alt;
    font-size: $font-size-large;
    border-radius: 0;

    &-primary {
        background: $brand-primary;

        &:hover, &:focus, &:active {
            background: $brand-primary-light !important;
        }

        &:focus, &:active:focus {
            box-shadow: none !important;
        }
    }

    &-secondary {
        background: $brand-secondary !important;
        color: $white;

        &:hover, &:focus, &:active {
            background: $brand-secondary-light !important;
            color: $white;
        }

        &:focus, &:active:focus {
            box-shadow: none !important;
        }
    }

    &-arrow {
        &:after {
            @include arrow-right;
            position: relative;
            top: 0; //$default-spacing/8;
            left: $default-spacing/3;
            font-size: $font-size-icon;
        }
    }

    &-white {
        color: $brand-primary;
        background-color: $white;

        &:hover, &:focus, &:active {
            background-color: darken($white, 5);
            box-shadow: 0 0 0 0.2rem rgba($white,.25);
        }
    }
}

.order {
    &-sm {
        &-1 {
            @include breakpoint-max(sm) {
                order: 1;
            }
        }

        &-2 {
            @include breakpoint-max(sm) {
                order: 2;
            }
        }

        &-3 {
            @include breakpoint-max(sm) {
                order: 3;
            }
        }

        &-4 {
            @include breakpoint-max(sm) {
                order: 4;
            }
        }
    }
}


.highlighted-link {
    color: $white;
    font-size: 2rem;
    display: inline-block;

    @include breakpoint-max(xs) {
        font-size: 1.4rem;
    }

    span:not(.icon) {
        border-bottom: 2px solid $brand-primary;
        word-break: break-word;
    }

    .icon {
        color: $white;
        transition: transform .3s;
        font-size: 1.5rem;
        transform: translateX(0);
        display: inline-block;
        margin-left: $default-spacing*0.25;
    }

    &:hover {
        color: $white;

        .icon {
            transform: translateX($default-spacing / 2);
        }
    }

    @include breakpoint-max(xs) {
        .icon {
            transform: translateY(2px) translateX(0);
        }
    }
}