﻿@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

.contact-button {

    &__trigger {
        position: fixed;
        right: $default-spacing*2;
        bottom: $default-spacing*2;
        z-index: 1;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $black;
        color: $white;
        text-align: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        padding: 0;

        &:hover, &:focus {
            outline: 0;
            background-color: lighten($black, 5);
        }

        .icon {
            font-size: $font-size-icon*2;
            line-height: 80px;
            display: block;
            transform: translate(1px, -2px); // bad icon...
        }

        @include breakpoint-max(xs) {
            width: 60px;
            height: 60px;
            right: $default-spacing;
            bottom: $default-spacing;

            .icon {
                font-size: $font-size-icon*1.5;
                line-height: 60px;
            }
        }
    }
    &__overlay {
        display: none;
    }

    @keyframes contactoverlay {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    &.active {
        .contact-button__trigger {
            color: $black;
            background-color: $white;
            z-index: 3;

            &:hover, &:focus {
                outline: 0;
                background-color: darken($white, 5);
            }

            .icon {
                transform: translate(0);
                &::before {
                    content: $kicon-close;
                }
            }
        }

        .contact-button__overlay {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $black; 
            color: $white;
            padding: $default-spacing*2;
            padding-bottom: 80px + $default-spacing;
            z-index: 2;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            animation: contactoverlay .2s ease-out;

            a {
                color: $white;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

html.contact-button-open {
    overflow: hidden;
}