﻿@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-primary-darker;
    padding: $header-height 0;
    max-width: 100%;
    max-height: 90vh; // fix for ultrawide screens
    min-height: 550px;

    //Vertical align text
    &.hero--v-top {
        align-items: flex-start;
    }

    &.hero--v-bottom {
        align-items: flex-end;
    }

    @include breakpoint-max(xs) {
        padding: $header-height-responsive 0;
    }

    .hero-inner {
        flex-grow: 1;
        width: 100%;
        position: relative;
        //z-index: 10;
    }
}



/*======================================
  #Overlay
======================================*/
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    //z-index: 5;
    background: $black;
    opacity: .3;
    transition: opacity .4s ease;
}




.hero {
    &.hero--fixed-height {
        padding: 0;
        overflow: hidden;

        > .hero-inner {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // .container
            .hero-inner-container {
                padding-top: $default-spacing * 2;
                padding-bottom: $default-spacing * 2;
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }

        &.hero--v-top {
            > .hero-inner {
                align-items: flex-start;
                padding-top: $header-height;

                @include breakpoint-max(xs) {
                    padding-top: $header-height-responsive;
                }
            }
        }

        &.hero--v-bottom {
            > .hero-inner {
                align-items: flex-end;
            }
        }

        .hero-fixed-image {
            width: 100%;
        }

        &.hero--fixed-height-920 {
            .hero-fixed-image {
                img {
                    max-height: 920px;
                    min-height: 500px;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    object-fit: cover;

                    @include breakpoint-max(sm) {
                        max-height: 500px;
                    }
                }
            }
        }

        &.hero--fixed-height-1040 {
            .hero-fixed-image {
                img {
                    max-height: 1040px;
                    min-height: 550px;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    object-fit: cover;

                    @include breakpoint-max(sm) {
                        max-height: 550px;
                    }
                }
            }
        }
    }
}







// highlighted links
.hero__highlighted-links {
    list-style-type: none;
    padding: 0;

    @include breakpoint-max(xs) {
        padding-left: $default-spacing/2;
        padding-right: $default-spacing/2;
    }

    li {
        margin-top: $default-spacing/2;
    }
}


// text
.hero__text {
    color: $white;

    @include breakpoint-max(xs) {
        padding-left: $default-spacing/2;
        padding-right: $default-spacing/2;
    }
}



// hero replacement
.hero-no-img {
    margin-top: $header-height;

    h1, p {
        padding-top: $default-spacing*2;
    }

    h1 + p {
        padding-top: 0;
    }

    @include breakpoint-max(xs) {
        margin-top: $header-height-responsive;

        h1, p {
            padding-left: $default-spacing/2;
            padding-right: $default-spacing/2;
        }
    }
}



// education hero
.hero--education {
    min-height: 500px;
    text-align: center;
    color: $white;

    .hero__text {
        margin-top: $header-height + $default-spacing;

        @include breakpoint-max(xs) {
            margin-top: $header-height-responsive + $default-spacing;
        }
    }

    &--no-image {
        background-color: $brand-primary-dark;

        .hero__text {
            margin-top: 0;
        }
    }

    &--small {
        min-height: 350px;

        .hero__text {
            margin-top: 115px;

            @include breakpoint-max(xs) {
                margin-top: $default-spacing*2.5;
            }
        }
    }
}

.hero__signup {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: $default-spacing;
    display: flex;
    justify-content: flex-end;

    @include breakpoint-max(xs) {
        padding: 0;
    }

    .signup-box {
        display: inline-flex;

        @include breakpoint-max(xs) {
            width: 100%;
        }

        &__text {
            width: 50%;
            background-color: rgba($black, .85);
            padding: $default-spacing;
            display: flex;
            align-items: center;

            .timer {
                font-size: $font-size-h3;
                font-weight: 700;
            }

            .description {
                text-align: left;
                margin-left: $default-spacing/2;
                font-size: $font-size-xs;
                font-weight: 300;
            }
        }
        &__btn {
            width: 50%;

            .btn {
                width: 100%;
                height: 100%;
                font-weight: bold;
                //padding: $default-spacing;
                min-height: $header-height-responsive;
                line-height: $header-height-responsive;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: $default-spacing;

                &::after {
                    margin-left: $default-spacing/2;
                }
            }
        }
    }
}