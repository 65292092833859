﻿@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

.anchor-menu-wrapper {
    width: 100%;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: $header-height + $default-spacing;
    z-index: 2;

    @include breakpoint-max(xs) {
        position: fixed;
        top: $header-height-responsive*2;
    }
}

.anchor-menu {
    position: absolute;
    top: $default-spacing*2;
    right: $default-spacing*2;

    @include breakpoint-max(xs) {
        right: $default-spacing;
        top: 0;
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &__body {
        display: none;
        background-color: $brand-primary;
        width: 400px;
        position: absolute;
        right: 50%;
        top: -$default-spacing;
        padding: $default-spacing;
        padding-right: 40px;
        box-shadow: 0 3px 14px -4px rgba($black,.58);
        animation: fade .2s ease-out;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @include breakpoint-max(xs) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - #{$default-spacing} - 30px);
        }

        a {
            color: $white;
            padding: $default-spacing/2 0;
            margin-bottom: $default-spacing;
            display: block;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__trigger {
        background-color: $brand-primary;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        color: $white;
        text-align: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        position: relative;
        z-index: 1;
        box-shadow: 0 3px 8px -4px rgba($black,.58);
        padding: 0;

        .icon {
            font-size: $font-size-icon + 4px;
            line-height: 80px;
            display: block;
            background-color: $brand-primary;
            border-radius: 50%;
        }

        &:hover, &:focus {
            outline: 0;
            background-color: lighten($brand-primary, 5);

            .icon {
                background-color: lighten($brand-primary, 5);
            }
        }

        @include breakpoint-max(xs) {
            width: 60px;
            height: 60px;

            .icon {
                line-height: 60px;
            }
        }
    }

    &.active {
        .anchor-menu__body {
            display: block;
        }

        .anchor-menu__trigger {
            box-shadow: none;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 50%;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                box-shadow: 0 3px 14px -4px rgba($black,.58);
                z-index: -1;
            }

            .icon {
                &::before {
                    content: $kicon-close;
                }
            }
        }

        @include breakpoint-max(xs) {
            &::before {
                content: "";
                display: block;
                background-color: rgba($black, .5);
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    &--hidden {
        display: none !important;
    }
}

/*html.anchor-menu {
    overflow: hidden;
}*/