﻿@font-face {
    font-family: 'Poppins';
    src: url('/Bundled/fonts/Poppins-Light.ttf?#iefix');
    src: url('/Bundled/fonts/Poppins-Light.ttf?#iefix') format('truetype'), url('/Bundled/fonts/Poppins-Light.woff2') format('woff2'), url('/Bundled/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('/Bundled/fonts/Poppins-Regular.ttf?#iefix');
    src: url('/Bundled/fonts/Poppins-Regular.ttf?#iefix') format('truetype'), url('/Bundled/fonts/Poppins-Regular.woff2') format('woff2'), url('/Bundled/fonts/Poppins-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('/Bundled/fonts/Poppins-Bold.ttf?#iefix');
    src: url('/Bundled/fonts/Poppins-Bold.ttf?#iefix') format('truetype'), url('/Bundled/fonts/Poppins-Bold.woff2') format('woff2'), url('/Bundled/fonts/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('/Bundled/fonts/Poppins-Black.ttf?#iefix');
    src: url('/Bundled/fonts/Poppins-Black.ttf?#iefix') format('truetype'), url('/Bundled/fonts/Poppins-Black.woff2') format('woff2'), url('/Bundled/fonts/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
