﻿/* Defines how text and text-elements should be styled 
 *    Headings,
 *    generic lists,
 *    Unstyled content (line-height, color etc.)
 *    Reference:
 *    * Resources\3rd-party\libraries\bootstrap\_type.scss */
@import "../../_custom-variables.scss";
@import "../../_custom-mixins.scss";

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0;
}

ul {
    margin: 0 0 $default-spacing;
}

.title {
    @include title;
}

blockquote {
    margin: 0 0 $default-spacing;
    padding: $default-spacing/2 $default-spacing;
    color: $gray-darker;
    font-size: $font-size-h3;
    font-style: italic;
    font-weight: lighter;
    border-left: 5px solid $gray;
}

/*Call To Action - "calltoactiontext" is THE name, beacuse we use this in OpenPublicWeb Rule Checker*/
.calltoactiontext {
    /*@include transition-std();
    border: none;
    padding: 7px $default-spacing/1.25;
    font-family: $font-family-alt;
    font-size: $font-size-large;
    display: inline-block;
    background: $brand-primary;
    color: $white !important;*/
    /*&.has-link {
        padding: 0px;

        a {
            @include transition-std();
            border: none;
            padding: 7px $default-spacing/1.25;
            font-family: $font-family-alt;
            font-size: $font-size-large;
            font-weight:500;
            display: inline-block;
            background: $brand-secondary;
            color: $white !important;


            &:hover, &:focus {
                background: $brand-secondary-light !important;
                color: $white !important;
                text-decoration: none;
            }

            &:after {
                
                position: relative;
                top: $default-spacing/8;
                left: $default-spacing/3;
            }
        }

        &:after{
            display: none;
        }
    }*/
    /*&:after {
        position: relative;
        top: $default-spacing/8;
        left: $default-spacing/3;
    }*/
    a {
        color: $white !important;
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-arrow;
        padding: $default-spacing;
        padding-right: $default-spacing*1.25;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
    }
}

.teaser {
    font-size: $font-size-teaser;
}