﻿/* 
    Fallback styling for things that have not been defined in modules, types or a utility 
*/
@import "../_custom-variables.scss";
@import "../_custom-mixins.scss";

html, body {
    //overflow-x: hidden;
}

html {
    font-size: $font-size-root-mobile;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @include breakpoint(xs) {
        font-size: $font-size-root;
    }
}

body {
    // overflow-y: scroll; 
    //overflow-x: hidden;
    word-wrap: break-word;
}

td {
    vertical-align: top;
}

table, td, th, caption {
    border: none;
}

a {
    color: $brand-primary;

    &:hover {
        color: $brand-primary-dark;
    }
}

.main-content {
    padding: $default-spacing*2 0 0;
}

.content-container {

    h1, h2, h3, h4, h5, h6 {
        color: $gray-darkest;
        font-family: $font-family-alt;
        padding: $default-spacing/2 0;
        font-weight: bold;
    }
}


.content {
    position: relative;
    top: 0;

    &__title {
        font-size: $font-size-h1;
        font-weight: bold;
    }

    &__teaser {
        font-size: $font-size-base;
    }

    &__image {
        width: 100%;

        img {
            width: 100%;
            max-width: 100%;
        }
    }

    &__rte {

        ul {
            margin-top: -$default-spacing/2;

            li {
                line-height: 2em;
            }
        }
    }
}


@include breakpoint-max(xs) {
    [class*="col-"] {
        padding-left: $default-spacing/4;
        padding-right: $default-spacing/4;
    }

    .row {
        margin-left: -$default-spacing/4;
        margin-right: -$default-spacing/4;
    }
}

.flex-container {
    -ms-box-orient: horizontal;
    display: flex;
    flex-flow: row wrap;

    &:before {
        content: none;
    }
}

input:not([type="radio"]):not([type="checkbox"]), textarea, button {
    -webkit-appearance: none;
    border-radius: 0;
}

.editor-login {
    @include transition-std;
    opacity: 0.075;
    position: absolute;
    right: $default-spacing/2;

    &:hover, &:focus {
        opacity: 0.275;
    }
}

#content {
    min-height: 50vh;
}