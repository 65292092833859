//MW Critical
//Critical is for styling that is critical for the page at first.
//This should make the site look "OK" and prevent "jumping" a bit, when the rest of the styling is loaded at the bottom of the page.
//We do this to not block DOM rendering and get fast performance.

@import "_custom-variables.scss";

// Core variables and mixins
@import "3rd-party/libraries/bootstrap/functions";
@import "3rd-party/libraries/bootstrap/variables";
@import "3rd-party/libraries/bootstrap/mixins";
/*@import "3rd-party/libraries/bootstrap/root";*/

// Reset and dependencies
@import "3rd-party/libraries/bootstrap/bootstrap-reboot.scss"; //normalize
@import "3rd-party/libraries/bootstrap/print";

// Core CSS
/*@import "3rd-party/libraries/bootstrap/scaffolding";*/
@import "3rd-party/libraries/bootstrap/type";
@import "3rd-party/libraries/bootstrap/grid";
@import "3rd-party/libraries/bootstrap/tables";
@import "3rd-party/libraries/bootstrap/forms";
@import "3rd-party/libraries/bootstrap/buttons"; 


// Utility classes
@import "3rd-party/libraries/bootstrap/utilities";
/*@import "3rd-party/libraries/bootstrap/_responsive-utilities.scss";*/



// Base
@import "base/_base.scss";
@import "base/_print.scss";
@import "base/_utilities.scss";
@import "base/type/_type.scss";
@import "modules/fonts/fonts.scss";


//Modules - Should be modules that often is "above the fold"
/*@import "modules/branding/_branding.scss";
@import "modules/breadcrumbs/_breadcrumbs.scss";
*/
@import "modules/header/_header.scss";
@import "modules/hero-image/_hero-image.scss";
@import "modules/breadcrumbs/_breadcrumbs.scss";
@import "modules/contact-button/_contact-button.scss";
@import "modules/anchor-menu/_anchor-menu.scss";


//Hide all icons untill the font has been loaded. See loaderheader.cshtml
a:after,
.link:before,
.kicon:before,
.nav__link:after,
.block__link:after,
.article__link:after,
.article__image:before,
.stamp--date.end:before,
.accordion__header:after,
.slick-dots:before,
.slick-prev:before,
.slick-next:before {
    opacity: 0;
    min-width: 1em;
    @include transition-std(opacity, linear, 200ms);
}

// components
//@import "components/hero/_hero.scss";